input, select {
    padding: 0.5rem;
    font-size: 1em;
    box-sizing: border-box;
    height: 2.5em;
}

select {
    background-color: white;
}
select:disabled {
    background-color: #f0f0f0;
}

.scala {
    margin-top: 1em;
    position: relative;
    z-index: 0;
}

.scala-slider {
    position: relative;
    display: block;
    height: 20px;
    width: 100%;
    margin: 0;
    padding: 0;
    appearance: none;
    cursor: pointer;
    border: none;
    background: 0 0;

    user-select: none;
    touch-action: manipulation;
    z-index: 1;
}

.scala-slider::-webkit-slider-runnable-track {
    height: 6px;
    background-color: darkcyan;
    border: 0;
    border-radius: 3px;
    user-select: none
}

.scala-slider::-webkit-slider-thumb {
    appearance: none;

    margin-top: -7px;
    
    height: 20px;
    width: 20px;
    border-radius: 100%;

    background-color:#ffffff;
    border: 3px solid darkcyan;

    box-shadow: 0 1px 2px rgba(52,63,74,.4);
    box-sizing: border-box;
    z-index: 1;
}

.scala-slider.unset::-webkit-slider-thumb {
    visibility: hidden;
}

.scala-slider:disabled::-webkit-slider-thumb {
    background-color: #e8e8e8;
    box-shadow: none;
}

.scala-pin {
    height: 5px;
    width: 1px;
    background-color:darkcyan;

    position: absolute;
    top: 4px;
    z-index: 0;
}

.scala-pin.left { left: .5rem; }
.scala-pin.right { right: .5rem; }
.scala-pin.middle { right: 50%; }

.scala-text {
    color: gray;
    position: absolute;
    top: -1rem;
    font-size: .7em;
}

.scala-text.left { text-align: left; left: 0; }
.scala-text.right { text-align: right; right: 0; }
.scala-text.middle { text-align: center; left: 0; width: 100%; }