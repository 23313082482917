.attribute-rating {
    position: relative;
    margin: 1em 0;
}
.attribute-rating-title {
    font-size: 1em;
    font-weight: bold;
}

.attribute-rating-subtitle {
    font-size: .9em;
    padding: .5em 0;
    font-weight: 100;
}

.attribute-rating-options {
    position: absolute;
    right: 0;
    top: 5px;
    font-size: .6em;
    color: darkslategray;
    cursor: pointer;
}