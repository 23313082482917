.collapsible {
    display: block;
    position: relative;
}

.collapsible-header {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 15px;

    cursor: pointer;
    border-radius: 3px 3px 0 0;
    background-color: darkcyan;
    color: #fafafa;
    box-shadow: 0px 1px 3px 0px rgba(150,150,150,0.75);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    user-select: none;
}

.collapsible-content {
    padding: 10px 15px;

    background-color: #fafafa;
    overflow: hidden;
    height: auto;

    border-radius: 0 0 3px 3px;
    box-shadow: 0px 1px 3px 0px rgba(150,150,150,0.75);
}

.collapsible-header-title {
    padding-right: 2em;
}