.user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
}

.user-info-title {
    margin: 1em 0;
    font-size: 1.2em;
    color: darkslategray;
    font-weight: 600;
    text-align: center;
}

.user-image {
    display: block;
    width: 6em;
    height: 6em;
    background-color: #ccc;
    background-size: cover;
    border-radius: 50%;
    border: 2px solid darkslategray;
}