.login-page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
}

.login-page-title {
    font-size: 1.2em;
    margin: 1rem;
}