.button {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 15px;

    cursor: pointer;
    border-radius: 3px;
    color: #fafafa;
    box-shadow: 0px 1px 3px 0px rgba(150,150,150,0.75);

    user-select: none;
}

.button:hover {
    color: white;
    filter: brightness(90%);
}