.statistics-page {
    width: 100%;
}

.attribute-statistics {
    width: 100%;
    margin: 1rem 0;
}

.statistics-page-button {
    margin: 1rem;
}