.chitalib-view {
    padding: 0 17%;
    flex: 1 1 auto;

	display: flex;
    flex-flow: row nowrap;
    align-content: stretch;

    overflow-x: hidden;
}

@media all and (max-width: 800px) {
    .chitalib-view {
        padding: 0;
    }
}